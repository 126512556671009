import axios from "axios";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NowCertLogo from "../../src/assets/images/NowCerts.png";
import CommissionScanLogo from "../../src/assets/images/GenerateCommission.svg";
import PFLogo from ".././assets/images/PF_Logo.svg";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AddApps from "../OtherPages/AddApps";
import FenrisLogo from "../assets/images/fenris.svg";
import VehicleLogo from "../assets/images/vehicle2.svg";
import AddAppsIcon from "../assets/images/plus.svg";
import Footer from "../common/Footer";
import Header from "../common/Header";
import CommonValues from "../common/utils";
import ScanIcon from "../assets/images/scan.svg";
import AtkButton from "../AtlaskitControls/AtkButton";
import { Dialog } from "primereact/dialog";
import ReactPlayer from "react-player";
import infoImg from "../assets/images/partnership.png";
import videoImg from "../assets/images/video-player.svg";
import infoImg2 from "../assets/images/info.svg";
import NaicsLogo from "../assets/images/NAICS_logo.png";
export default function LandingPage() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [widgetData, setWidgetData] = useState<any[]>([]);
  const [isVisibleWidget, setIsVisibleWidget] = useState<any>([]);
  const [isVisibleWidgetList, setIsVisibleWidgetList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [settingLoading, setSettingLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showPrimaryAmsMessage, setShowPrimaryAmsMessage] = useState(false);
  const [showOCR, setShowOCR] = useState(false);
  const appsRef = useRef<OverlayPanel>(null);
  const [nowCertsMessageShow, setNowCertsMessageShow] = useState(false);
  const location = useLocation();
  const hostname = window.location.hostname;
  const [showVideoDialog, setShowVideoDialog] = useState(false);
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const [videoUrl, setVideoUrl] = useState("");
  useEffect(() => {
    const token = CommonValues.GetToken();

    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    if (localStorage.getItem("PFSettings") == "true") {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
    if (localStorage.getItem("tenantId") === "0") {
      window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
    }
    getCommonSettings();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const getCommonSettings = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcommonsettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setSettingLoading(true);
    axios(config)
      .then((response: any) => {
        localStorage.setItem("tenantId", response.data.pfTenantId);
        localStorage.setItem("clientCount", response.data.clientCount);
        localStorage.setItem("HawksoftSettings", JSON.stringify(response.data));

        if (response.data.pfTenantId == 0) {
          setSettingLoading(false);
        }
        if (response.data.agencyId != null && response.data.agencyId != "") {
          localStorage.setItem("HawksoftAgencyId", "true");
        } else {
          localStorage.setItem("HawksoftAgencyId", "false");
          if (response.data.pfTenantId == process.env.REACT_APP_Hawksoft_Tenant_Id) {
            window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
          }
        }
        if (response.data.pfList == 0) {
          localStorage.setItem("PFSettings", "true");
          setShowMessage(true);
        } else {
          localStorage.setItem("PFSettings", "false");
          setShowMessage(false);
        }

        if (response.data.pfTenantId == process.env.REACT_APP_NowCerts_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Nowcert");
          setShowPrimaryAmsMessage(false);
        } else if (response.data.pfTenantId == process.env.REACT_APP_Hawksoft_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Hawksoft");
          if (hostname == process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) {
            setShowPrimaryAmsMessage(true);
          } else {
            setShowPrimaryAmsMessage(false);
          }
          setSettingLoading(false);
        } else if (response.data.pfTenantId == process.env.REACT_APP_Vertafore_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Vertafore");
          if (hostname == process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) {
            setShowPrimaryAmsMessage(true);
          } else {
            setShowPrimaryAmsMessage(false);
          }
          getVertaforeSettings();
        }
        if (response.data.pfTenantId == process.env.REACT_APP_NowCerts_Tenant_Id) {
          getNowCertsCreds();
        } else {
        }
      })
      .catch((error: any) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  const getNowCertsCreds = () => {
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getcredentials`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setSettingLoading(false);
        if (response.data.ncUserName == null || response.data.ncUserName == null) {
          localStorage.setItem("NowCertsSettings", "false");
          setNowCertsMessageShow(true);
          window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
          setShowOCR(false);
        } else {
          localStorage.setItem("NowCertsSettings", "true");
          setNowCertsMessageShow(false);
          setShowOCR(true);
        }
      })
      .catch((error) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the NowCerts Setting details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the NowCerts Setting details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  const getVertaforeSettings = () => {
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/vertaforesettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.data.agencyName == "" || response.data.agencyName == null || response.data.userName == "" || response.data.userName == null) {
          localStorage.setItem("VertaforeSetting", "true");
          window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
        } else {
          localStorage.setItem("VertaforeSetting", "false");
        }
        setSettingLoading(false);
      })
      .catch((error: any) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the vertafore settings";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        }
      });
  };
  const getUserWidgets = () => {
    setLoading(true);
    var data = JSON.stringify({
      searchText: "",
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_AUTHAPI}/api/account/getuserwidgets`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setLoading(false);
        setWidgetData(response.data.map((widget: any) => widget.widgetCode));
        setIsVisibleWidgetList(response.data.map((widget: any) => widget.isVisibleOnChrLandingPage));
        setIsVisibleWidget(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          }
        }
      });
  };
  const onNewQuoteClick = () => {
    navigate("/premiumfinance");
  };
  const onCoiScanClick = () => {
    navigate("/nowcertcoiscan");
  };
  const onCommissionScanClick = () => {
    navigate("/commissionscan");
  };
  const onSmartScanClick = () => {
    navigate("/smartscan");
  };
  const onFenrisTileClick = () => {
    navigate("/fenrisprefill");
  };
  const onSettingTileClick = () => {
    navigate("/pfsettings");
  };
  const gotopfsettingPage = () => {
    {
      window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
    }
  };
  const onVehiclesClick = () => {
    navigate("/vehicleprefill");
  };
  const onPFSmartScanClick = () => {
    navigate("/pfscan");
  };
  const onInfoClick = () => {
    setShowInfoDialog(true);
  };
  const onVideoClick = () => {
    setShowVideoDialog(true);
  };
  const onCloseVideoDialogClick = () => {
    setShowVideoDialog(false);
  };
  const onCloseInfoDialogClick = () => {
    setShowInfoDialog(false);
  };
  const onNaicsClassificationClick = () => {
    navigate("/naicscode");
  };
  return (
    <div className="landing-main-page">
      <Toast ref={toast} />

      <Header />
      <div className="content-wrapper container-fluid landing-content-wrapper only-landing-wrapper">
        <div className="row  mx-auto p-2 wrapper-below-div d-flex ">
          <div className="mb-5">
            <h2 className="font-bold text-light mb-4">Welcome to Momentum ToolBox</h2>
            <p className="text-light">Streamline your insurance processes with our suite of powerful tools. Select a service below to get started.</p>
          </div>
          {settingLoading ? (
            <div className="col-12  d-flex align-items-center justify-content-center align-items-center">
              <AtkLoader />
            </div>
          ) : (
            <>
              {showMessage && (
                <div className="col-12 my-3 text-start text-16 text-light">
                  <span>
                    Please{" "}
                    <a
                      onClick={onSettingTileClick}
                      style={{
                        color: "#7ba340",
                      }}
                      className="show-cursor"
                    >
                      choose{" "}
                    </a>{" "}
                    Premium Finance partner(s).
                  </span>
                </div>
              )}
              <div
                className={`landing-page-card-col ${
                  showPrimaryAmsMessage ? "col-lg-3 " : "col-lg-2"
                } col-md-6 col-sm-12 mb-2 d-flex justify-content-center ${showMessage ? "flex-column" : ""} `}
              >
                {showPrimaryAmsMessage ? (
                  <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center text-center">
                    <span className="text-dark p-2">
                      This version of the website does not support your AMS; please use the <b>Chrome Extension</b>.
                    </span>
                  </div>
                ) : localStorage.getItem("tenantId") === "0" ? (
                  <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center">
                    <span className="text-16 text-dark p-2 text-center">
                      Please{" "}
                      <a onClick={gotopfsettingPage} className="no-primary-ams">
                        select
                      </a>{" "}
                      your primary AMS
                    </span>
                  </div>
                ) : nowCertsMessageShow && CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id ? (
                  <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center text-center">
                    <span className="text-dark">
                      Please{" "}
                      <a onClick={gotopfsettingPage} className="no-primary-ams">
                        provide
                      </a>{" "}
                      your NowCerts credentials to continue.
                    </span>
                  </div>
                ) : CommonValues.GetTenantId() === process.env.REACT_APP_Hawksoft_Tenant_Id &&
                  (localStorage.getItem("HawksoftAgencyId") === "false" ||
                    localStorage.getItem("HawksoftAgencyId") == null ||
                    localStorage.getItem("HawksoftAgencyId") === "") ? (
                  <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center  justify-content-center text-center">
                    <span className="text-dark">
                      Please{" "}
                      <a onClick={gotopfsettingPage} className="no-primary-ams">
                        provide{" "}
                      </a>{" "}
                      your HawkSoft agency id to continue.
                    </span>
                  </div>
                ) : CommonValues.GetTenantId() === process.env.REACT_APP_Vertafore_Tenant_Id &&
                  (localStorage.getItem("VertaforeSetting") === "true" ||
                    localStorage.getItem("VertaforeSetting") == null ||
                    localStorage.getItem("VertaforeSetting") === "") ? (
                  <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center text-center">
                    <span className="text-dark">
                      Please{" "}
                      <a onClick={gotopfsettingPage} className="no-primary-ams">
                        provide{" "}
                      </a>{" "}
                      your AMS360 credentials to continue.
                    </span>
                  </div>
                ) : (
                  <>
                    <div className="service-card show-cursor" onClick={onNewQuoteClick}>
                      <span className=" show-cursor">
                        <div className="card-header">
                          <img src={PFLogo} alt="Service Logo" className="service-logo" />
                          <h2 className="service-title">PremFi</h2>
                        </div>
                        <p className="service-description">Manage premium financing with ease</p>
                      </span>
                      <div className="row">
                        <div className="col-6">
                          {/* <div className="card-buttons ">
                            <img
                              src={infoImg2}
                              title="Information"
                              className="me-2 show-cursor z-3 "
                              height={20}
                              onClick={(e) => {
                                e.stopPropagation();
                                onInfoClick();
                              }}
                            />
                            <img
                              src={videoImg}
                              title="Video"
                              className="me-2 show-cursor z-3 "
                              height={20}
                              onClick={(e) => {
                                e.stopPropagation();
                                onVideoClick();
                              }}
                            />
                          </div> */}
                        </div>
                        <div className="col-6">
                          <div className="card-buttons-2 ">
                            <AtkButton label="Get Started" className="pf-primary-btn" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <OverlayPanel ref={appsRef} className="addapps-panel">
                  <AddApps />
                </OverlayPanel>
              </div>

              {window.innerWidth > 767 ? (
                <>
                  {showOCR ? (
                    <>
                      {(hostname == process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) &&
                      CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id ? (
                        <>
                          <div className="landing-page-card-col col-lg-2 col-md-6 col-sm-12 mb-2 d-flex justify-content-center ">
                            <div className="service-card show-cursor" onClick={onCoiScanClick}>
                              <span className=" show-cursor">
                                <div className="card-header">
                                  <img src={NowCertLogo} alt="Service Logo" className="service-logo" />
                                  <h2 className="service-title">Certificate of Insurance Scan</h2>
                                </div>
                                <p className="service-description">Quickly scan and process certificates of insurance</p>
                              </span>
                              <div className="row">
                                <div className="col-6">
                                  {/* <div className="card-buttons ">
                                    <img
                                      src={infoImg2}
                                      title="Information"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onInfoClick();
                                      }}
                                    />
                                    <img
                                      src={videoImg}
                                      title="Video"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onVideoClick();
                                      }}
                                    />
                                  </div> */}
                                </div>
                                <div className="col-6">
                                  <div className="card-buttons-2 ">
                                    <AtkButton label="Get Started" className="pf-primary-btn" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="landing-page-card-col col-lg-2 col-md-6 col-sm-12 mb-2 d-flex justify-content-center">
                            <div className="service-card show-cursor" onClick={onSmartScanClick}>
                              <span className=" show-cursor">
                                <div className="card-header">
                                  <img src={NowCertLogo} alt="Service Logo" className="service-logo" />
                                  <h2 className="service-title">Insurance Form Scan</h2>
                                </div>
                                <p className="service-description">Efficiently scan and extract data from Insurance forms</p>
                              </span>
                              <div className="row">
                                <div className="col-6">
                                  {/* <div className="card-buttons ">
                                    <img
                                      src={infoImg2}
                                      title="Information"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onInfoClick();
                                      }}
                                    />
                                    <img
                                      src={videoImg}
                                      title="Video"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onVideoClick();
                                      }}
                                    />
                                  </div> */}
                                </div>
                                <div className="col-6">
                                  <div className="card-buttons-2 ">
                                    <AtkButton label="Get Started" className="pf-primary-btn" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="landing-page-card-col col-lg-2 col-md-6 col-sm-12 mb-2 d-flex justify-content-center">
                            <div className="service-card show-cursor" onClick={onFenrisTileClick}>
                              <span className=" show-cursor">
                                <div className="card-header">
                                  <img src={FenrisLogo} alt="Service Logo" className="service-logo" />
                                  <h2 className="service-title">AMS Prefill</h2>
                                </div>
                                <p className="service-description">Automate your AMS data entry process</p>
                              </span>
                              <div className="row">
                                <div className="col-6">
                                  {/* <div className="card-buttons ">
                                    <img
                                      src={infoImg2}
                                      title="Information"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onInfoClick();
                                      }}
                                    />
                                    <img
                                      src={videoImg}
                                      title="Video"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onVideoClick();
                                      }}
                                    />
                                  </div> */}
                                </div>
                                <div className="col-6">
                                  <div className="card-buttons-2 ">
                                    <AtkButton label="Get Started" className="pf-primary-btn" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="landing-page-card-col col-lg-2 col-md-6 col-sm-12 mb-2 d-flex justify-content-center">
                            <div className="service-card show-cursor" onClick={onPFSmartScanClick}>
                              <span className=" show-cursor">
                                <div className="card-header">
                                  <img src={ScanIcon} alt="Service Logo" className="service-logo" />
                                  <h2 className="service-title">PremFi Scan</h2>
                                </div>
                                <p className="service-description">Streamline premium finance document processing</p>
                              </span>
                              <div className="row">
                                <div className="col-6">
                                  {/* <div className="card-buttons ">
                                    <img
                                      src={infoImg2}
                                      title="Information"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onInfoClick();
                                      }}
                                    />
                                    <img
                                      src={videoImg}
                                      title="Video"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onVideoClick();
                                      }}
                                    />
                                  </div> */}
                                </div>
                                <div className="col-6">
                                  <div className="card-buttons-2 ">
                                    <AtkButton label="Get Started" className="pf-primary-btn" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="landing-page-card-col col-lg-2 col-md-6 col-sm-12 mb-2 d-flex justify-content-center">
                            <div className="service-card show-cursor" onClick={onVehiclesClick}>
                              <span className=" show-cursor">
                                <div className="card-header">
                                  <img src={VehicleLogo} alt="Service Logo" className="service-logo" />
                                  <h2 className="service-title">Vehicle Prefill</h2>
                                </div>
                                <p className="service-description">Quickly populate vehicle information forms</p>
                              </span>
                              <div className="row">
                                <div className="col-6">
                                  {/* <div className="card-buttons ">
                                    <img
                                      src={infoImg2}
                                      title="Information"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onInfoClick();
                                      }}
                                    />
                                    <img
                                      src={videoImg}
                                      title="Video"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onVideoClick();
                                      }}
                                    />
                                  </div> */}
                                </div>
                                <div className="col-6">
                                  <div className="card-buttons-2 ">
                                    <AtkButton label="Get Started" className="pf-primary-btn" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="landing-page-card-col col-lg-2 col-md-6 col-sm-12 mb-2 d-flex justify-content-center">
                            <div className="service-card show-cursor" onClick={onCommissionScanClick}>
                              <span className=" show-cursor">
                                <div className="card-header">
                                  <img src={CommissionScanLogo} alt="Service Logo" className="service-logo" />
                                  <h2 className="service-title">Commission Scan</h2>
                                </div>
                                <p className="service-description">Easily extract and manage commission data</p>
                              </span>
                              <div className="row">
                                <div className="col-6">
                                  {/* <div className="card-buttons ">
                                    <img
                                      src={infoImg2}
                                      title="Information"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onInfoClick();
                                      }}
                                    />
                                    <img
                                      src={videoImg}
                                      title="Video"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onVideoClick();
                                      }}
                                    />
                                  </div> */}
                                </div>
                                <div className="col-6">
                                  <div className="card-buttons-2">
                                    <AtkButton label="Get Started" className="pf-primary-btn" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="landing-page-card-col col-lg-2 col-md-6 col-sm-12 mb-2 d-flex justify-content-center">
                            <div className="service-card show-cursor" onClick={onNaicsClassificationClick}>
                              <span className=" show-cursor">
                                <div className="card-header">
                                  <img src={NaicsLogo} alt="Service Logo" className="service-logo" />
                                  <h2 className="service-title">NAICS Classification </h2>
                                </div>
                                <p className="service-description">
                                  Append NAICS Codes to your Customer to easily identify the industries of all your clients
                                </p>
                              </span>
                              <div className="row">
                                <div className="col-6">
                                  {/* <div className="card-buttons ">
                                    <img
                                      src={infoImg2}
                                      title="Information"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onInfoClick();
                                      }}
                                    />
                                    <img
                                      src={videoImg}
                                      title="Video"
                                      className="me-2 show-cursor z-3 "
                                      height={20}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onVideoClick();
                                      }}
                                    />
                                  </div> */}
                                </div>
                                <div className="col-6">
                                  <div className="card-buttons-2">
                                    <AtkButton label="Get Started" className="pf-primary-btn" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
      <Footer />
      <Dialog visible={showVideoDialog} style={{ width: "50vw" }} onHide={onCloseVideoDialogClick} draggable={false} className="videoDialog">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="">
              <div className="">
                <ReactPlayer
                  className="react-video-dialog"
                  width="100%"
                  height="450px"
                  url={"https://www.youtube.com/watch?v=Zc7-p_UPgRs"}
                  controls={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog visible={showInfoDialog} style={{ width: "70vw" }} onHide={onCloseInfoDialogClick} draggable={false} className="infoDialog">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <img src={infoImg} className="w-100" />
                <div className="article-title-text">
                  <h2 className="font-weight-600">InsuranceGIG and NowCerts Integration Partnership</h2>
                  <span className="text-16">September 16, 2022</span>
                </div>
                <p className="text-14">
                  InsuranceGIG, a revolutionary API AppMarket that connects buyers and sellers of Insurance Technology today announced a new
                  partnership with NowCerts, an Agency Management System (AMS) leader.{" "}
                </p>
                <p className="text-14">
                  The integration between the two partners will provide NowCerts agents access to InsuranceGIG insurtech services embedded directly
                  into their AMS. Once implemented, NowCerts agents will be able to seamlessly look up and populate business classification codes
                  (NAICS) without leaving their core system. Additionally, agents will gain access to InsuranceGIG’s services that help with data
                  transformation, electronic forms prefill, and more.
                </p>
                <p className="text-14">
                  Michael Lebor, the CEO of InsuranceGIG commented, “We're very excited about our partnership with NowCerts. The NowCerts team has
                  built a truly powerful digital first Agency Management System (AMS). At InsuranceGIG, we know that agencies want solutions embedded
                  directly in the AMS that work seamlessly with the core system. Our partnership can significantly reduce the need for NowCerts
                  clients to log into multiple solutions. The NowCerts / InsuranceGig partnership has been architected from the very beginning to
                  bring more value to the independent agent, and we are grateful to NowCerts for the opportunity to bring this unique solutions to
                  their valued agency customers.”
                </p>
                <p className="text-14">
                  “In the increasingly digitized workflows of a modern Independent insurance agency, agents and CSRs rely on a growing number of tools
                  to automate and simplify their work days. A modern AMS needs to provide its users with an easy way to connect their main data
                  repository with these satellite tools and data sources. InsuranceGIG provides an easy way for an agency to build its custom
                  ecosystem without hiring armies of developers or relying on the AMS to do the integration. It takes the integration and design
                  decision out of the hands of a few vendors and into the hands of the Agency. We at NowCerts are very excited to partner with
                  InsuranceGIG and offer all of their vendor integrations to our members.” offered Peter Germanov, the Principal at NowCerts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
