import React from 'react'
import fenrisLogo from "../../assets/images/Fenris digital logo.png";


export default function FenrisMiddleHeader() {
  return (
    <div className="row middle-header-row">
    <div className="col-12">
      <div className="middle-header-col active ms-2">
        <img src={fenrisLogo} className="" width={30}/>
        <span className="middle-header-title">AMS PreFill</span>
      </div>
    </div>{" "}
  </div>  )
}
