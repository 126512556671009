import React from 'react'
import naicsLogo  from "../assets/images/images.jpeg";

export default function NaicsMiddleHeader() {
  return (
    <div className="row middle-header-row">
      <div className="col-12">
        <div className="middle-header-col active ms-2">
          <img src={naicsLogo} className="" width={30}/>
          <span className="middle-header-title">Naics Classification</span>
        </div>
      </div>{" "}
    </div>
  )
}
