import axios from "axios";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import PfdIcon from "../../assets/images/file.png";
import PreviewIcon from "../../assets/images/paper.png";
import RemoveIcon from "../../assets/images/trashcan.png";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import COIScanMiddleHeader from "./COIScanMiddleHeader";
import COIScanSidebar from "./COIScanSidebar";

export default function NowCertsCOIScan() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [uploadFile, setUploadFile] = useState<any>([]);
  const [uploadFileLimit, setUploadFileLimit] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [filesCount, setFilesCount] = useState(0);
  const [requestFileCount, setRequestFileCount] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [uploadingRetry, setUploadingRetry] = useState(false);
  const [incompleteRequestData, setIncompleteRequestData] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState("");
  const [isfileuploaded, setIsfileuploaded] = useState(false);
  const [fileNameTitle, setFileNameTitle] = useState("");
  const [fileName, setFileName] = useState("");
  const [currentBalance, setCurrentBalance] = useState("0");
  const [currentBalanceLoading, setCurrentBalanceLoading] = useState(false);

  useEffect(() => {
    getCurrentBalance();
  }, []);

  const getCurrentBalance = () => {
    setCurrentBalanceLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_STRIPEPAYMENTGATEWAY}/api/billing/current-balance`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setCurrentBalance("" + response.data.currentBalance / 100);
        }
        setCurrentBalanceLoading(false);
      })
      .catch((error) => {
        setCurrentBalanceLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while getting current balance",
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            detail: "Unknown error while getting current balance",
            life: 3000,
          });
        }
      });
  };
  const onDropHandler = (event: any) => {
    event.preventDefault();
    onFileSelectHandler(event.dataTransfer.files);
  };
  
  const validateSelectedFiles = (files: any) => {
    var regularExpression = /(?:\.([^.]+))?$/;
    var isValid = true;

    for (let index = 0, len = files.length; index < len; index++) {
      const fileExtension: any = regularExpression.exec(files[index].name);
      if (fileExtension === undefined || fileExtension[0] == "") {
        isValid = false;
        break;
      }
      if (fileExtension?.[1].toLowerCase().includes("pdf")) {
        isValid = true;
      } else {
        isValid = false;
        break;
      }
    }
    return isValid;
  };
  const onFileSelectHandler = (files: any) => {
    setFileName("");
    setFileNameTitle("");
    const isValidated = validateSelectedFiles(files);
    if (!isValidated) {
      setErrorMessage("Please select PDF files only");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return false;
    }
    let fileList = [];
    let totalSize = 0;
    let currentFiles = uploadFile;
    let filename = files[0].name;
    if (filename.length > 50) {
      let shortenString = filename.substring(0, 50).concat("...");
      setFileName(shortenString);
      setFileNameTitle(filename);
    } else {
      setFileName(filename);
      setFileNameTitle(filename);
    }
    for (let i = 0, len = files.length; i < len; i++) {
      fileList.push({
        file: files[i],
        name: files[i].name,
        size: files[i].size,
      });
      totalSize = totalSize + files[i].size;
    }

    if (fileList.length > 0) {
      if (currentFiles.length > 0) {
        for (var i = 0, l = currentFiles.length; i < l; i++) {
          for (var j = 0; j < fileList.length; j++) {
            if (currentFiles[i].name === fileList[j].name) {
              fileList.splice(j, 1);
            }
          }
        }
      }
      let allFiles = currentFiles.concat(fileList);
      if (allFiles.length > uploadFileLimit) {
        allFiles = allFiles.slice(0, uploadFileLimit);
        setErrorMessage("Multiple files cannot be uploaded at the same time; please upload a single file");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      }
      setUploadFile(allFiles);
      setFilesCount(allFiles.length);
      setRequestFileCount(allFiles.length);
      setFilesCount(allFiles.length);
    }
  };
  const removeFileFromList = () => {
    setUploadFile([]);
    setIsfileuploaded(false);
    setSuccessMessage("");
  };
  const createUploadRequest = () => {
    if (uploadFile.length == 0) {
      setTimeout(() => {
        setErrorMessage("Please select at least one file to upload");
      }, 3000);
      return;
    }

    var FormData = require("form-data");
    var data = new FormData();
    data.append("file", uploadFile[0].file);
    const token = CommonValues.GetToken();
    setLoading(true);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/coiscan`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        if (response.status === 200) {
          if (response.data.success) {
            setCsvData(response.data.nowcertsCOIScanResponse);
            setLoading(false);
            setIsfileuploaded(true);
            setSuccessMessage(
              "File uploaded successfully! We are processing your file. Please check the History tab to find out the file's current progress status."
            );
            setTimeout(() => {
              setSuccessMessage("");
              setUploadFile([]);
              setIsfileuploaded(false);
            }, 4000);
            getCurrentBalance();
          } else if (!response.data.success && response.data.errorMessage === "User does not have enough credits for NowCerts COI Scan.") {
            setLoading(false);
            toast.current.show({
              severity: "info",
              detail: response.data.errorMessage,
              life: 3000,
            });
          }
        } else {
          setErrorMessage("Something went wrong.");
          setLoading(false);
          setIsfileuploaded(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null && error.response.status == 401) {
          navigate("/");
          CommonValues.Logout(navigate);
        } else {
          setErrorMessage("Error while creating the request.");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
          setLoading(false);
          setIsfileuploaded(false);
        }
      });
  };

  return (
    <div className="nowcerts-coi-scan-page">
      <Toast ref={toast} />
      <Header />
      <div className="content-wrapper">
        <div className="row  wrapper-main-row mx-auto wrapper-below-div">
          <div className="col-12">
            <COIScanMiddleHeader />
          </div>
          <div className="row main-content-row mx-auto ">
            <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
              <COIScanSidebar pagename="coi-scan" />
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
            <div className="row   d-flex  align-items-end text-12 position-relative">

              <div className="col-lg-12 col-md-12 col-sm-12 main-content-col">
                <div className="row client-search-row ">
                  <div className="col-12 d-flex align-items-center justify-content-end">
                    <div className="row w-100 d-flex align-items-center justify-content-end">
                      <div className="col-7"></div>

                      <div className="col-3 text-end">
                        <label>
                          <b>Current Balance</b>
                        </label>
                      </div>
                      <div className="col-2">
                        {currentBalanceLoading ? (
                          <div className="d-flex align-items-center  justify-content-center p-2 ">
                            <AtkLoader />
                          </div>
                        ) : (
                          <div className="input-group">
                            <span className="input-group-text">$</span>
                            <AtkTextField className="input-field form-control p-0" type="text" id="text" value={currentBalance} isDisabled={true} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="uploadNowcertscoi my-3"
                onDrop={(e) => {
                  onDropHandler(e);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="row w-100 ">
                  {uploadFile.length > 0 ? (
                    <div className="col-12">
                      {uploadFile.map((file: any, index: any) => (
                        <div className="show-pdf-icon">
                          <div className="file-name">
                            <div className="preview-icon">
                              <img src={PreviewIcon} />
                            </div>
                            <div className="filename">
                              <div title={fileNameTitle}>{fileName}</div>
                              <div>{(parseInt(file.size) / 1000).toFixed(0)}KB</div>
                            </div>
                          </div>
                          {uploadComplete ? (
                            <div>
                              <span className="uploaded">Uploaded</span>
                            </div>
                          ) : (
                            uploadingFiles == false && (
                              <div onClick={() => removeFileFromList()} className="show-cursor">
                                <img src={RemoveIcon} />
                              </div>
                            )
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <>
                      <>
                        <div className="row" style={{ textAlign: "center" }}>
                          <div className="col-12">
                            <img src={PfdIcon} alt="" width="70" />
                          </div>

                          <div className="col-12 mt-4">
                            <div className="col-12">
                              <span className="drag-and-drop">Drag & Drop file here</span>
                            </div>
                            <div className="col-12 my-2">
                              <span
                                style={{
                                  color: "black",
                                  fontSize: "large",
                                }}
                              >
                                or
                              </span>
                            </div>
                            <label className="mb-0 show-cursor">
                              <span className="browse">Browse PDF</span>
                              <input
                                type="file"
                                name="filefield"
                                accept=".pdf"
                                id="bulkFileUploader"
                                style={{
                                  visibility: "hidden",
                                  display: "none",
                                }}
                                onChange={(e) => {
                                  onFileSelectHandler(e.target.files);
                                }}
                              />
                            </label>
                          </div>
                          <div className="coi-info mt-3">
                            <span className="text-14 mt-2 info">Supported document type: Certificate Of Insurance (Acord 25)</span>
                          </div>
                        </div>
                      </>
                    </>
                  )}

                  <div className="col-12 text-center">
                    {isfileuploaded ? null : (
                      <>
                        {uploadingFiles == false && incompleteRequestData == false && uploadingRetry == false && uploadFile.length > 0 && (
                          <div className="d-flex justify-content-center" style={{ width: "100%", padding: "20px" }}>
                            {loading == false ? (
                              <>
                                <AtkButtonIconBefore
                                  label="Upload"
                                  className="pf-secondary-btn"
                                  onClick={() => {
                                    createUploadRequest();
                                  }}
                                />
                              </>
                            ) : (
                              <div className="d-flex justify-content-center align-items-center p-2">
                                <AtkLoader></AtkLoader>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}

                    {errorMessage.length > 0 ? (
                      <div className="d-flex justify-content-center Acord25-upload-file-error" style={{ width: "100%", padding: "20px 20px 0px" }}>
                        <AtkMessage appearance="error" messageText={errorMessage} />
                      </div>
                    ) : null}
                    {successMessage.length > 0 ? (
                      <div className="d-flex justify-content-center upload-file-error" style={{ width: "100%", padding: "20px 20px 0px" }}>
                        <AtkMessage appearance="success" messageText={successMessage} />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
           
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
