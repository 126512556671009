import React from "react";
import SmartScanSidebar from "./SmartScanSidebar";
import SmartScanMiddleHeader from "./SmartScanMiddleHeader";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Toast } from "primereact/toast";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import CommonValues from "../../common/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import moment from "moment";
import AtkButton from "../../AtlaskitControls/AtkButton";
import feedbackImg from "../../assets/images/feedback.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import DownloadIcon from "../../assets/images/icon _download_.png";
import PreviewIcon from "../../assets/images/ViewDetailsIcon.png";
import { Dialog } from "primereact/dialog";
import TextField from "@atlaskit/textfield";
import AtkTextField from "../../AtlaskitControls/AtkTextField";

export default function SmartScanHistory() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [historyloading, setHistoryLoading] = useState(true);
  const [smartscanhistorydata, setSmartScanHistoryData] = useState<any>([]);
  const [submissionId, setSubmissionId] = useState();
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [previewFileLoading, setPreviewFileLoading] = useState(false);
  const [extractedData, setExtractedData] = useState<any>([]);
  const [activeTab, setActiveTab] = useState("Insured");
  const [firstName,setFirstName]=useState("");
  const [middleName,setMiddleName]=useState("");
  const [lastName,setLastName]=useState("");
  const [email,setEmail]=useState("");
  const [phoneNumber,setPhoneNumber]=useState("");
  const [businessName,setBusinessName]=useState("");
  const [dbaName,setDBAName]=useState("");
  const [fein,setFein]=useState("");
  const [businessEmail,setBusinessEmail]=useState("");
  const [address,setAddress]=useState("");
  const [city,setCity]=useState("");
  const [state,setState]=useState("");
  const [zipCode,setZipCode]=useState("");
  const [policyNumber,setPolicyNumber]=useState("");
  const [policyPremium,setPolicyPremium]=useState("");
  const [carrierName,setCarrierName]=useState("");
  const [lineOfBusiness,setLineOfBusiness]=useState("");
  const [quoteNumber,setQuoteNumber]=useState("");
  const [policyEffectiveDate,setPolicyEffectiveDate]=useState("");
  const [policyExpiryDate,setPolicyExpiryDate]=useState("");

  useEffect(() => {
    getSmartScanHistory();
  }, []);

  const getSmartScanHistory = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/getsmartscanhistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setSmartScanHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting SmartScan history", life: 3000 });
          }
        }
        setHistoryLoading(false);
      });
  };

  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if(newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };

  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setSmartScanHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.submissionId === rowData.submissionId  ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };

  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setSmartScanHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.submissionId === rowData.submissionId ? { ...response, isLiked: liked } : response))
    );
  };

  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      setSubmissionId(rowData.submissionId);
      onSaveFeedback(rowData);
    }
  };

  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.submissionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscan/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };

  const formatPhoneNumber = (phoneNumber: string) => {
    const numericValue = phoneNumber.replace(/\D/g, "");
    const phoneNumberLength = numericValue.length;
    if (phoneNumberLength <= 3) {
      return numericValue;
    } else if (phoneNumberLength <= 6) {
      return `(${numericValue.slice(0, 3)})${numericValue.slice(3)}`;
    } else {
      return `(${numericValue.slice(0, 3)})${numericValue.slice(3, 6)}-${numericValue.slice(6, 10)}`;
    }
  };

  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.submissionId,
      IsLiked: rowData.isLiked,
      Feedback: feedbackVal,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/smartscan/isliked`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Ratings Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };

  const statusTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <b className="text-success"> Success</b>
    ) : rowData.status === "Failed" ? (
      <b className="text-danger"> Failed</b>
    ) : null;
  };

  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };

  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedBackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };

  const actionTemplate = (rowData: any) => {
    return rowData.status === "Success" ? (
      <>
        <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData)} title="Preview" className="show-cursor" />
      </>
    ) : (
      <>
        <span className=" text-12 quote-not-span">Not Available</span>
      </>
    );;
  };


  const onPreviewClick = (rowData: any) => {
    setShowPopUp(true);
    setPreviewFileLoading(true);
    setExtractedData(JSON.parse(rowData.responseJson))
    const responseJson=JSON.parse(rowData.responseJson);
    if(responseJson.documentType =="Acord130"){
    let name = responseJson.ContactInformation[0].Name.split(" ");
    setFirstName(name[0] == null || name[0] == "" ? "":name[0])
    setLastName(name[1] == null || name[1] == "" ? "":name[1])
    if (responseJson.ApplicantEmail != "" && responseJson.ApplicantEmail != null) {
      setEmail(responseJson.ApplicantEmail);
      setBusinessEmail(responseJson.ApplicantEmail);
    } else {
      setEmail(responseJson.ContactInformation[0].Email);
      setBusinessEmail(responseJson.ContactInformation[0].Email);
    }
    setLineOfBusiness("Worker's Compensation");
    setPolicyPremium("0");
    if (responseJson.ContactInformation[0].MobilePhone != "" && responseJson.ContactInformation[0].MobilePhone != null) {
      let phoneNumber=responseJson.ContactInformation[0].MobilePhone.replace(/ /g, "")
      phoneNumber = formatPhoneNumber(phoneNumber);
      setPhoneNumber(phoneNumber);
    } else {
        let phoneNumber = responseJson.ContactInformation[0].OfficePhone.replace(/ /g, "");
        phoneNumber = formatPhoneNumber(phoneNumber);
        setPhoneNumber(phoneNumber);
    }

    if (responseJson.ApplicantName != "" && responseJson.ApplicantName != null) {
      setBusinessName(responseJson.ApplicantName);
    }
    else{
      setBusinessName("");
    }
      setAddress(responseJson.MailingAddress.AddressLine1);
      setCity(responseJson.MailingAddress.City);
      setState(responseJson.MailingAddress.State);
      setZipCode(responseJson.MailingAddress.ZipCode);
      setPolicyExpiryDate(responseJson.PolicyExpiryDate);
      setPolicyEffectiveDate(responseJson.PolicyEffectiveDate);
      setQuoteNumber("");
    }
    const token = CommonValues.GetToken();
    var data = {
      TransactionId: rowData.submissionId,
      FileName: rowData.fileName,
      Target: "SmartScan"
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/previewdocument`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response: any) => {
        setPdfUrl(response.data.fileUrl);
        setPreviewFileLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting  history details", life: 3000 });
          }
        }
        setPreviewFileLoading(false);
      });
      setPreviewFileLoading(false);
  };

  const onHidePopupClicked = () => {
    setShowPopUp(false);
    setPdfUrl("");
  };
  const handleTabClick = (tabName: any) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="smart-scan-history-main-page">
        <Header pagename="smart-scan-history" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <SmartScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <SmartScanSidebar pagename="smart-scan-history" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  history-table mt-3">
                    {historyloading ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <AtkLoader />{" "}
                      </div>
                    ) : (

                      <DataTable
                        value={smartscanhistorydata}
                        paginator
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                        rows={10}
                        selectionMode="single"
                      >
                        <Column header="File Name" field="fileName"></Column>
                        <Column
                          header="Submitted Date"
                          field="created"
                          body={(rowData: any) => {
                            if (rowData.created != null || rowData.created !== "") {
                              return moment.utc(rowData.created, "YYYY-MM-DD").format("DD-MM-YYYY");
                            }
                          }}
                        ></Column>
                        <Column header="Status" field="status" body={statusTemplate}></Column>
                        <Column header="Action" body={actionTemplate} ></Column>
                        <Column header="Feedback" body={feedbackTemplate}></Column>
                      </DataTable>
                    )}
                  </div>
                  <Dialog header="" visible={showPopUp} style={{ width: "80vw", height: "90vh" }} onHide={onHidePopupClicked} draggable={false}>
                    {previewFileLoading ? (
                      <div className="d-flex align-items-center justify-content-center overflow-hidden">
                        <AtkLoader />
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-6 border-end">
                          <b>Uploaded PDF</b>
                          <iframe src={pdfUrl} style={{ overflow: "scroll", height: "75vh", width: "100%" }} className="mt-2"></iframe>
                        </div>
                        <div className="col-6 history-table">
                          <div className="mt-2">
                            <b>Extracted data</b>
                            <div
                              className="col p-2 ms-2"
                              style={{
                                border: "1px solid darkgrey",
                                borderRadius: "30px",
                                minHeight: "66vh",
                              }}
                            >
                              <div className="row p-0 d-flex align-items-center mx-auto smartscan-tab">
                                <div className="col-6 d-flex align-items-center justify-content-start">
                                  <span
                                    className={
                                      activeTab === "Insured"
                                        ? "policy-name-span active show-cursor"
                                        : "policy-name-span show-cursor"
                                    }
                                    onClick={() => {
                                      handleTabClick("Insured");
                                    }}
                                  >
                                    Insured Details
                                  </span>
                                  <span
                                    className={
                                      activeTab === "Policy"
                                        ? "policy-name-span active show-cursor"
                                        : "policy-name-span show-cursor"
                                    }
                                    onClick={() => {
                                      handleTabClick("Policy");
                                    }}
                                  >
                                    Policy Details
                                  </span>
                                </div>
                              </div>

                              {extractedData.documentType != "Acord130" ?
                              <>
                              {activeTab === "Insured" ? (
                                <>
                                  <div className="row  my-2 mx-auto">
                                    <div className="col-12">
                                      <span className="text-14 ">
                                        <b>Insured Information</b>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row smartscan-block  mx-auto ">
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <label>First Name</label>
                                      {extractedData.documentType == "DecPage" ?
                                      <TextField
                                         className="input-field"
                                        type="text"
                                        value={extractedData.name == null ? "":extractedData.name}
                                        isDisabled={true}
                                      />:
                                      <TextField
                                      className="input-field"
                                     type="text"
                                     value={extractedData.firstName == null ? "":extractedData.firstName}
                                     isDisabled={true}
                                   />}
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <label>Last Name</label>
                                      <TextField
                                        isDisabled={true}
                                         className="input-field"
                                        type="text"
                                        value={extractedData.lastName == null ? "":extractedData.lastName }
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <label>Email</label>
                                      <TextField
                                        className="input-field"
                                        type="text"
                                        value={extractedData.email == null ?"" :extractedData.email}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <label>Phone Number</label>
                                      <TextField
                                         className="input-field"
                                        isDisabled={true}
                                        type="text"
                                        value={extractedData.phone == null ?"" : extractedData.phone}
                                      />
                                    </div>
                                  </div>

                                  <div className="row  my-2  mx-auto">
                                    <div className="col-12">
                                      <span className="text-14 ">
                                        <b>Business Information</b>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row smartscan-block  mx-auto">
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Business Name</span>
                                      {extractedData.documentType == "Acord126" ?
                                      <TextField
                                         className="input-field"
                                        type="text"
                                        isDisabled={true}
                                        value={extractedData.ApplicantName== null ?"" :extractedData.ApplicantName}
                                      />:
                                      <TextField
                                         className="input-field"
                                        type="text"
                                        isDisabled={true}
                                        value={extractedData.businessName== null ?"" :extractedData.businessName}
                                      />
}

                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>DBA</span>
                                      <TextField
                                         className="input-field"
                                        type="text"
                                        value={extractedData.dba== null ?"" :extractedData.dba}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Fein</span>
                                      <TextField
                                         className="input-field"
                                        type="text"
                                        value={extractedData.fein == null ?"" : extractedData.fein}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Business Email</span>
                                      <TextField
                                         className="input-field"
                                        type="text"
                                        value={extractedData.businessEmail == null ?"" :extractedData.businessEmail}
                                        isDisabled={true}
                                      />
                                    </div>
                                  </div>

                                  <div className="row  my-2  mx-auto">
                                    <div className="col-12">
                                      <span className="text-14 ">
                                        <b>Address Information</b>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row smartscan-block  mx-auto">
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Address</span>
                                      <TextField
                                        className="input-field"
                                        type="text"
                                        value={extractedData.addressLine1 == null ? "":extractedData.addressLine1}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>City</span>
                                      <TextField
                                       className="input-field"
                                        type="text"
                                        value={extractedData.city == null ?"": extractedData.city}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>State</span>
                                      <TextField
                                        className="input-field"
                                        type="text"
                                        value={extractedData.state == null ? "":extractedData.state}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Zip Code</span>
                                      <TextField
                                        className="input-field"
                                        type="text"
                                        value={extractedData.zipCode == null ?"":extractedData.zipCode}
                                        isDisabled={true}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row  my-2 mx-auto">
                                    <div className="col-12">
                                      <span className="text-14 ">
                                        <b>Policy Information</b>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row smartscan-block mx-auto">
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Policy Number</span>
                                      <TextField
                                       className="input-field"
                                        type="text"
                                        value={extractedData.policyNumber == null ?"":extractedData.policyNumber}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Policy Premium ($)</span>
                                      {extractedData.documentType == "Acord126" ? 
                                      <TextField
                                       className="input-field"
                                        type="text"
                                        value={extractedData.Premium == null? "":extractedData.Premium}
                                        isDisabled={true}
                                      />
                                      :
                                      <TextField
                                      className="input-field"
                                       type="text"
                                       value={extractedData.policyPremium == null? "":extractedData.policyPremium}
                                       isDisabled={true}
                                     />
}
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Carrier Name</span>
                                      <TextField
                                        className="input-field"
                                        type="text"
                                        value={extractedData.carrierName == null ? "" :extractedData.carrierName}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Line of Business</span>
                                      {extractedData.documentType == "DecPage" ? 
                                      <TextField
                                        className="input-field"
                                        type="text"
                                        value={extractedData.lineOfBusinessName == null ? "" :extractedData.lineOfBusinessName}
                                        isDisabled={true}
                                      />:
                                      <TextField
                                      className="input-field"
                                      type="text"
                                      value={extractedData.LineOfBusiness == null ? "" :extractedData.LineOfBusiness}
                                      isDisabled={true}
                                    />

}
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Quote Number</span>
                                      {extractedData.documentType == "DecPage" ?
                                      <TextField
                                        className="input-field"
                                        type="text"
                                        value={extractedData.quoteNumber == null ? extractedData.policyNumber: extractedData.quoteNumber}
                                        isDisabled={true}
                                      />
                                      :
                                      <TextField
                                        className="input-field"
                                        type="text"
                                        value={extractedData.quoteNumber == null ? "": extractedData.quoteNumber}
                                        isDisabled={true}
                                      />}
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Effective Date</span>
                                      {extractedData.documentType == "Acord126" ? 
                                      <AtkTextField
                                       className="input-field"
                                        type="date"
                                        id="datepicker"
                                        value={extractedData.EffectiveDate != null || extractedData.EffectiveDate !== "" ? moment.utc(extractedData.EffectiveDate).format("YYYY-MM-DD") : ""}
                                        isDisabled={true}
                                      />:
                                      <AtkTextField
                                       className="input-field"
                                        type="date"
                                        id="datepicker"
                                        value={extractedData.effectiveDate != null || extractedData.effectiveDate !== "" ? moment.utc(extractedData.effectiveDate).format("YYYY-MM-DD") : ""}
                                        isDisabled={true}
                                      />}
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Expiration Date</span>
                                      {extractedData.documentType == "Acord126" ? 
                                      <AtkTextField
                                       className="input-field"
                                        type="date"
                                        id="datepicker"
                                        value={extractedData.ExpiryDate !=null || extractedData.ExpiryDate !=="" ?moment.utc(extractedData.ExpiryDate).format("YYYY-MM-DD"):""}
                                        isDisabled={true}
                                      />:
                                      <AtkTextField
                                      className="input-field"
                                       type="date"
                                       id="datepicker"
                                       value={extractedData.proposedExpDate !=null || extractedData.proposedExpDate !=="" ?moment.utc(extractedData.proposedExpDate).format("YYYY-MM-DD"):""}
                                       isDisabled={true}
                                     />}
                                    </div>
                                  </div>
                                </>
                              )}
                              </> 
                               :
                               <>
                                {activeTab === "Insured" ? (
                                <>
                                  <div className="row  my-2 mx-auto">
                                    <div className="col-12">
                                      <span className="text-14 ">
                                        <b>Insured Information</b>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row smartscan-block  mx-auto ">
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <label>First Name</label>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={firstName}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <label>Middle Name</label>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={middleName}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <label>Last Name</label>
                                      <TextField
                                        isDisabled={true}
                                        className=""
                                        type="text"
                                        value={lastName}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <label>Email</label>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={email == null ?"" :email}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <label>Phone Number</label>
                                      <TextField
                                        className=""
                                        isDisabled={true}
                                        type="text"
                                        value={phoneNumber == null || "" || undefined ? "" : phoneNumber}
                                      />
                                    </div>
                                  </div>

                                  <div className="row  my-2  mx-auto">
                                    <div className="col-12">
                                      <span className="text-14 ">
                                        <b>Business Information</b>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row smartscan-block  mx-auto">
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Business Name</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        isDisabled={true}
                                        value={businessName}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>DBA</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={dbaName== null || "" ?"" :dbaName}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Fein</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={fein == null || "" ?"" : fein}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Business Email</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={businessEmail == null  || ""?"" :businessEmail}
                                        isDisabled={true}
                                      />
                                    </div>
                                  </div>

                                  <div className="row  my-2  mx-auto">
                                    <div className="col-12">
                                      <span className="text-14 ">
                                        <b>Address Information</b>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row smartscan-block  mx-auto">
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Address</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={address == null || "" ? "":address}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>City</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={city == null || "" ?"": city}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>State</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={state == null || "" ? "":state}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Zip Code</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={zipCode == null || "" ?"":zipCode}
                                        isDisabled={true}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row  my-2 mx-auto">
                                    <div className="col-12">
                                      <span className="text-14 ">
                                        <b>Policy Information</b>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row smartscan-block mx-auto">
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Policy Number</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={policyNumber}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Policy Premium ($)</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={policyPremium}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Carrier Name</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={carrierName}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Line of Business</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={lineOfBusiness}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Quote Number</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={quoteNumber}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Effective Date</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={policyEffectiveDate != null || policyEffectiveDate !== "" ? policyEffectiveDate: ""}
                                        isDisabled={true}
                                      />
                                    </div>

                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
                                      <span>Expiration Date</span>
                                      <TextField
                                        className=""
                                        type="text"
                                        value={policyExpiryDate !=null || policyExpiryDate !=="" ?policyExpiryDate:""}
                                        isDisabled={true}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                               </>
                            }


                            </div>
                          </div>
                        </div>

                      </div>
                    )}
                  </Dialog>

                  <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                    <div className="row mx-auto d-flex align-items-center mb-2">
                      <div className="col-12 feedback-header">
                        <h4>Feedback</h4>
                      </div>
                      <div className={"form-group col-12 mb-2 text-12"}>
                        <textarea
                          name="feedback"
                          onChange={(e) => onFeedbackChange(e.target.value)}
                          value={feedBackData.feedback}
                          className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                          rows={4}
                          placeholder="Enter your feedback here"
                        />
                        <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                      </div>
                      <div className="col-4 d-flex justify-content-evenly">
                        <i
                          className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
               ${feedBackData.isLiked == true ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbsUpClick(feedBackData)}
                        ></i>
                        <i
                          className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                ${feedBackData.isLiked == false ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbDownClick(feedBackData)}
                        ></i>
                      </div>
                      <div className="col-4 text-end p-2 pe-0  ">
                        <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                      </div>
                      <div className="col-4 text-end p-2  ">
                        {submissionId == feedBackData.submissionId && feedbackLoading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <AtkLoader />
                          </div>
                        ) : (
                          <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
                        )}
                      </div>
                    </div>
                  </OverlayPanel>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
