import React from 'react'
import NowCertLogo from "../../assets/images/NowCerts.png";

export default function COIScanMiddleHeader() {
  return (
    <div className="row middle-header-row">
    <div className="col-12">
      <div className="coi-scan-middle-header-col active ms-2">
        <img src={NowCertLogo} className="landing-page-img" />
        <span className="middle-header-title">COI Scan</span>
      </div>
    </div>{" "}
  </div>
  )
}
