import React from "react";
import pfLogo from "../../assets/images/PF_Logo.svg";

export default function PFScanMiddleHeader() {
  return (
    <div className="row middle-header-row">
      <div className="col-12">
        <div className="middle-header-col active ms-2">
          <img src={pfLogo} className="" width={30} />
          <span className="middle-header-title">PremFi scan</span>
        </div>
      </div>{" "}
    </div>
  );
}
